// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import dataTables from '@src/views/tables/data-tables/store'
import orgSettings from '@src/views/org-settings/store'
import subAccounts from '@src/views/sub-accounts/store'
import payments from '@src/views/payment/store'
import invoices from '@src/views/invoice/store'
import products from '@src/views/product/store'
import subscriptions from '@src/views/subscription/store'
import affiliates from '@src/views/affiliate/store'


const rootReducer = {
  auth,
  navbar,
  layout,
  payments,
  subscriptions,
  invoices,
  affiliates,
  products,
  dataTables,
  subAccounts,
  orgSettings
}

export default rootReducer
