// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getPaymentGateway = createAsyncThunk('orgSettings/getPaymentGateway', async () => {
  const response =  await axios.get('payment/setup/')
  return response.data
})

export const getGhlIntegration = createAsyncThunk('orgSettings/getGhlIntegration', async () => {
  const response = await axios.get('integration/ghl-oauth/')
  return response.data
})

export const getPlans = createAsyncThunk('orgSettings/getPlans', async () => {
  const response = await axios.get('subscription/plan/')
  return response.data
})

export const getSubAccounts = createAsyncThunk('orgSettings/getSubAccounts', async () => {
  const response = await axios.get('organization/sub-account/')
  return response.data
})

export const updatePaymentGateway = createAsyncThunk('orgSettings/setupPaymentGateway', async params => {
  console.log("updatePaymentGateway")
  const response = await axios.post('payment/setup/', params)
  return {data:response.data}
})

export const sendInviteToSetup = createAsyncThunk('orgSettings/sendInviteToSetup', async params => {
  console.log("updatePaymentGateway")
  const response = await axios.post('payment/invite-to-setup/', params)
  return { data: response.data }
})

export const updatePaymentPlan = createAsyncThunk('orgSettings/updatePaymentPlan', async (params, { dispatch, getState }) => {
  await axios.post('subscription/plan/', params)
  // await dispatch(getData(getState().paymentGateway.params))
  await dispatch(getPlans())
  return params
})

export const appUsersSlice = createSlice({
  name: 'orgSettings',
  initialState: {
    data: [],
    paymentGateways: null,
    subAccounts: [],
    paymentGatewayResponse: {},
    inviteToSetupResponse: null,
    paymentPlans:[],
    ghlIntegration: null,
    params: {},
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPaymentGateway.fulfilled, (state, action) => {
        state.paymentGateways = action.payload
        // state.params = action.payload.params
      })
      .addCase(getGhlIntegration.fulfilled, (state, action) => {
        console.log("getGhlIntegration", action.payload)
        state.ghlIntegration = action.payload
      })
      .addCase(sendInviteToSetup.fulfilled, (state, action) => {
        console.log("sendInviteToSetup", action.payload)
        state.inviteToSetupResponse = action.payload.data
      })
      .addCase(updatePaymentGateway.fulfilled, (state, action) => {
        console.log("action response", action.payload.data)
        state.paymentGatewayResponse = action.payload.data
      })
      .addCase(getSubAccounts.fulfilled, (state, action) => {
        state.subAccounts = action.payload
      })
      // .addCase(updatePaymentPlan.fulfilled, (state, action) => {
      //   state.paymentPlans = action.payload
      // })
      .addCase(getPlans.fulfilled, (state, action) => {
        state.paymentPlans = action.payload
      })
  }
})

export default appUsersSlice.reducer
