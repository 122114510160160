// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'


export const getData = createAsyncThunk('affiliates/getData', async params => {
  const response = await axios.get('/affiliate/', {})
  return {
    params,
    data: response.data,
  }
})

export const getAffiliateCode = createAsyncThunk('affiliates/getAffiliateCode', async params => {
  const response = await axios.get('/affiliate/', {})
  return {
    params,
    data: response.data,
  }
})

export const getSignups = createAsyncThunk('affiliates/getSignups', async params => {
  const response = await axios.get('/affiliate/signup/', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getStats = createAsyncThunk('products/getStats', async params => {
  const response = await axios.get('/product/stats/', {params})
  return {
    data: response.data
  }
})

export const appUsersSlice = createSlice({
  name: 'products',
  initialState: {
    affiliateLink: null,
    signups: {params:{}, data:{}, totalPages:0},
    params: {},
    allData: [],
    stats: {},
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAffiliateCode.fulfilled, (state, action) => {
        console.log("getData.fulfilled product", action.payload)
        state.affiliateLink = action.payload.data?.link
      })
      .addCase(getSignups.fulfilled, (state, action) => {
        console.log("getData.fulfilled product", action.payload)
        state.signups = action.payload
      })
  }
})

export default appUsersSlice.reducer
